import "./Home.css";
// import HomeEvent from "../../components/HomeEvent/HomeEvent";
import HomeSlider from "../../components/HomeSlider/HomeSlider";
import Campaign from "../../components/Campaign";
import { NavLink } from "react-router-dom";

export default function Home() {
  return (
    <main>
      <HomeSlider />
      <div className="home max-w-6xl mx-auto px-4">
        <header className="text-center flex flex-col gap-5 my-5">
        <h2 className="text-xl w-2/3 mx-auto">Asian Americans Advancing Justice is a national affiliation of four independently led, operated, and funded civil rights nonprofit organizations.</h2>
          <h3 className="text-lg font-semibold">Our Mission</h3>
          <h2 className="text-xl w-2/3 mx-auto">
            To promote a fair and equitable society for all by working for civil and human rights that empower Asian Americans and Pacific Islanders and other undeserved communities.
          </h2>
        </header>

        <section className="flex gap-3 my-5 flex-col sm:flex-row justify-center">
          <div className="flex flex-col gap-4">
            <h1 className="text-center text-aajc-orange font-bold text-3xl">
              21
            </h1>
            <p className="text-center text-sm">
              Asian languages supported
            </p>
          </div>

          <div className="flex flex-col gap-4">
            <h1 className="text-center text-aajc-orange font-bold text-3xl">
              16,200
            </h1>
            <p className="text-center text-sm">
              Clients assisted through our direct services
            </p>
          </div>

          <div className="flex flex-col gap-4">
            <h1 className="text-center text-aajc-orange font-bold text-3xl">
              146,068
            </h1>
            <p className="text-center text-sm">
              People trained in bystander intervention
            </p>
          </div>
        </section>

        <div className="flex justify-center my-5">
          <NavLink
            to="/about-us"
            className="border border-dark px-5 py-1 text-sm font-bold rounded-md"
          >
            LEARN MORE
          </NavLink>
        </div>
        
        {/* <section className="item flex-col gap-3">
          <div style={{background: "linear-gradient(90deg, rgba(243,124,43,1) 0%, rgba(246,60,70,1) 100%)", height: "250px", padding: "25px"}}>
            <div style={{border: "8px solid white", height: "100%",position: "relative"}}>
              <div style={{position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}>
                <p style={{color: "white"}}>
                  <strong>
                    Asian Americans Advancing Justice Denounces <br />
                    Supreme Court Decision on Affirmative Action
                  </strong>
                </p>
              </div>
            </div>
          </div>
          <div className="text-sm text-center my-5">
            <p>
              <strong>
                <NavLink
                  to="/news/press-release"
                  className="border border-dark px-5 py-1 text-sm font-bold rounded-md"
                >
                  <span>PRESS RELEASE</span>
                </NavLink>
              </strong>
            </p>
          </div>
        </section> */}
        <Campaign />
        {/* <HomeEvent /> */}
      </div>
    </main>
  );
}
