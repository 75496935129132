import sanityClient from "../client";
import imageUrlBuilder from "@sanity/image-url";
import { useState, useEffect } from "react";
import PortableText from "react-portable-text";

export default function Campaign(props) {
  const builder = imageUrlBuilder(sanityClient);
  function urlFor(source) {
    return builder.image(source);
  }
  const [campaigns, setCampaigns] = useState(null);
  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "campaign"]{
      image{
      asset->{
        _id,
        url
      },
    },
    body
    }`
      )
      .then((data) => {
        setCampaigns(data);
      })
      .catch(console.error);
  }, []);

  return (
    <section className="my-20">
      <header>
        <h1 className="text-2xl font-bold text-center mb-20">
          Our Shared Work
        </h1>
      </header>
      <main className="affliationWideCampaigns flex flex-col sm:flex-row flex-wrap gap-12 justify-evenly">
        {campaigns !== null && (
          <>
            {campaigns.map((campaign, key) => (
              <section key={key} className="item sm:w-5/12 flex flex-col gap-3">
                <img src={urlFor(campaign.image.asset.url).url()} alt="" />
                <PortableText
                  content={campaign.body}
                  className="text-sm text-center"
                />
              </section>
            ))}
            <section className="item sm:w-5/12 flex flex-col gap-3">
              <img src="/ARH.png" alt="" style={{borderRadius: "4px"}} />
              <div className="text-sm text-center">
                <p>Browse our National Asian American and Native Hawaiian & Pacific Islander Resource Directory to find service providers and community organizations across the country who serve the Asian American community.</p>
                <p><strong><a className="underline text-sm"><span>ASIAN RESOURCE HUB</span></a></strong></p>
              </div>
            </section>
            <section className="item sm:w-5/12 flex flex-col gap-3">
              <img src="/Looking_Ahead_ AANHPI_AACT.png" alt="" style={{borderRadius: "4px"}} />
              <div className="text-sm text-center">
                <p>This toolkit is designed by Asian Americans Advancing Justice to support the AANHPI community in framing the future of racial equity and diversity in higher education following the Supreme Court’s decision on affirmative action.</p>
                <p>
                  <strong>
                    <a
                      href="https://docs.google.com/presentation/d/1zL2lVMYRqu5NTmpm0btbbzpEdzfSauUrQcNjxw6Vy8Q/edit?usp=sharing"
                      target="_blank"
                      rel="noreferrer"
                      className="underline text-sm"
                    >
                      <span>AFFIRMATIVE ACTION</span>
                    </a>
                  </strong>
                </p>
              </div>
            </section>
          </>
        )}
      </main>
    </section>
  );
}
