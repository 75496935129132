import "./styles/NewsDetail.css";
import { Link } from "react-router-dom";

export default function NewsPressRelease() {
    return (
        <div className="max-w-6xl w-full mx-auto p-10">
            <Link to="/news" className="underline text-sm">
                &#60; Back to news
            </Link>
            <main className="newsDetail">
                <header className="my-8">
                    <h1 className="text-4xl font-bold tracking-tight mb-2 text-aajc-orange">
                        Asian Americans Advancing Justice Denounces <br /> Supreme Court Decision on Affirmative Action
                    </h1>
                    <h2>
                        {/* Published on January 12, 2024 */}
                    </h2>
                </header>
                <section>
                    <ul className="flex flex-wrap gap-2">
                    <li className="uppercase bg-aajc-lightorange text-aajc-darkgrey text-sm font-bold px-2 py-1 rounded-lg">
                        Press Release
                    </li>
                    <li className="uppercase bg-aajc-darkgrey text-white text-sm font-bold px-2 py-1 rounded-lg">
                        Washington
                    </li>
                    </ul>
                </section>
                <section>
                    <p>
                        <strong>Media Contact:</strong><br />
                        Michelle Boykins, Advancing Justice – AAJC, <a href="mailto:mboykins@advancingjustice-aajc.org">mboykins@advancingjustice-aajc.org</a><br />
                        Niketa Kumar, Advancing Justice - Asian Law Caucus, <a href="mailto:media@advancingjustice-alc.org">media@advancingjustice-alc.org</a><br />
                        Liana Liang, AJSOCAL, <a href="mailto:lliang@ajsocal.org">lliang@ajsocal.org</a><br />
                        James Woo, Advancing Justice - Atlanta, <a href="mailto:jwoo@advancingjustice-atlanta.org">jwoo@advancingjustice-atlanta.org</a><br />
                        Grace Pai, Advancing Justice | Chicago, <a href="mailto:gpai@advancingjustice-chicago.org">gpai@advancingjustice-chicago.org</a><br />
                    </p>
                </section>
                <section>
                    <p>Washington, D.C. – June 29, 2023 – In response to the Supreme Court’s decision today in SFFA v. Harvard and SFFA v. UNC striking down the race-conscious admissions policies at both schools, Asian Americans Advancing Justice (Advancing Justice) denounced the decision as an attack on civil rights and called for an increased commitment from universities and policymakers to ensure equitable opportunities for all students and diversity in higher education. </p>
                    <p>Advancing Justice, an affiliation of five independent Asian American civil rights organizations across the nation, has long advocated for race-conscious admissions policies as an essential tool to provide equal opportunities for students of color, including Asian Americans.</p>
                    <p>In its decision, the Supreme Court’s hyper-partisan majority struck a deep blow to students and racial equity in education by holding that Harvard and UNC’s affirmative action programs violated the equal protection clause of the Fourteenth Amendment. The ruling does not address race-conscious policies in any other contexts, such as in K-12 education, in the workplace, in government contracts, in philanthropic giving, or elsewhere.</p>
                    <p>“We are outraged that the Supreme Court has chosen to ignore long-standing legal precedent in favor of supporting racial inequity that harms all people of color, including Asian Americans,” said <strong>John C. Yang, President and Executive Director of Advancing Justice – AAJC. </strong>“But we are more committed than ever to ensuring equal opportunity for our children – and for all children in this country. We will not let this court decision keep us from pushing colleges and universities, Congress, and others to keep today’s ruling from undermining the progress made toward educating future multiracial, talented leaders who deserve every opportunity to reach their highest potential on campuses that reflect the diversity of America.”</p>
                    <p>“In today's ruling, the Supreme Court's majority ignores our long history and present reality of systematically denying people of color, particularly Black Americans, equal access to education,” said <strong>Aarti Kohli, Executive Director of Advancing Justice – Asian Law Caucus. </strong>“Racism and anti-Blackness are inescapable in our country, and race-conscious admissions are a crucial tool in expanding opportunities for students of color that reckons with those realities. For Asian Americans, this ruling will particularly harm Pacific Islander, Native Hawaiian, and Southeast Asian communities who continue to face significant barriers to higher education. At a moment when our country is increasingly segregated and there are significant gaps in resources for majority minority schools, we call on Congress, our local elected leaders, and universities to do everything in their power to implement solutions we really need for economic equity and racial justice in our nation.”</p>
                    <p>“For Asian American students and all others, racially diverse student bodies both enhance their learning and foster understanding of each student’s lived experience,” said <strong>Connie Chung Joe, CEO of Asian Americans Advancing Justice Southern California.</strong> “In our ever-changing global economy and platform, we must continue to give all students the opportunity to fulfill their potential and shape a future built strong on our biggest asset – our diversity.”</p>
                    <p>“We unequivocally condemn the ruling by the Supreme Court to reverse these affirmative action policies, thus ensuring that systemic racism continues to be embedded in higher education,” said <strong>Grace Pai, Executive Director of Advancing Justice – Chicago. </strong> “As Asian Americans, we will not allow our communities to be used as a prop by the conservative movement to advance a white supremacist agenda.”</p>
                    <p>“Declaring these race conscious policies unconstitutional is a barely disguised tool to once again remove students of color from our nation’s campuses and perpetuate the historical legacy of institutionalized racism in education that continues, especially in the south,” <strong>said Phi Nguyen, Executive Director of Advancing Justice – Atlanta.</strong> “We will continue to fight for racial justice and ensure that everyone has access to a quality education."</p>
                    <p>
                        Last August, Advancing Justice filed 
                        <a
                            href="https://www.advancingjustice-alc.org/news-resources/news/asian-american-civil-rights-groups-file-amicus-briefs-in-support-of-holistic-admissions"
                            target="_blank"
                            rel="noreferrer"
                        > two amicus briefs</a>
                        in SFFA v. Harvard — one of which was joined by 37 Asian American and Pacific Islander (AAPI) civil rights groups, advocacy organizations, professionals, and student organizations — demonstrating how AAPI communities both benefit from and support affirmative action, and detailing how the elimination of race consideration in admissions programs will harm Black, Latinx, Indigenous, Asian, and other students of color.
                    </p>
                    <p>Although the ruling impacts race-conscious admissions in higher education, colleges and universities have a responsibility to promote and embrace diversity, including cultivating affinity groups, increasing meaningful outreach to communities of color, expanding financial assistance, and dismantling legacy admissions programs. Advancing Justice calls for them to urgently prioritize these actions. It also calls for policymakers and other leaders to use this moment to set a comprehensive agenda for achieving equity in education long before students apply to college, including expanding access in K-12 schools, which are more segregated today than they were 50 years ago.</p>
                    <p><strong>Asian Americans Advancing Justice</strong></p>
                    <p>Asian Americans Advancing Justice is a national affiliation of five leading organizations advocating for the civil and human rights of Asian Americans and other underserved communities to promote a fair and equitable society for all. The affiliation's members are: Advancing Justice - AAJC (Washington, DC), Advancing Justice - Asian Law Caucus, Advancing Justice Southern California (AJSOCAL), Advancing Justice - Atlanta, and Advancing Justice - Chicago.</p>
                </section>
            </main>
        </div>
    );
}