import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import sanityClient from "../../client";
import imageUrlBuilder from "@sanity/image-url";
import "./AboutUs.css";

export default function AboutUs(props) {
  const [affiliates, setAffiliates] = useState(null);
  const builder = imageUrlBuilder(sanityClient);
  function urlFor(source) {
    return builder.image(source);
  }
  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "affiliate" && !(_id in path("drafts.**"))]{
      name,
      slug,
      link,
      publishedAt,
      bio,
      image{
        asset->{
          _id,
          url
        },
      },
    }`
      )
      .then((data) => setAffiliates(data))
      .catch(console.error);
  }, []);

  return (
    <div>
      <div className="max-w-6xl mx-auto px-4">
        <header className="flex flex-col gap-7 text-center">
          <h2 className="text-aajc-orange font-bold text-3xl ">
            Together, we fight anti-Asian hate and discrimination.
          </h2>
          <p>
            Asian Americans Advancing Justice is a national affiliation of four 
            leading organizations advocating for the civil and human rights of
            Asian Americans and other underserved communities to promote a fair and 
            equitable society for all.
          </p>
          <p>We are independently led, operated and funded.</p>
          <p>
            To achieve our mission, we work together—as equal and independent
            partners under one name, speaking with one unified and powerful
            voice—to promote justice, empower our communities, bring local and national
            constituents together, and strengthen our multi-racial democracy.
          </p>
        </header>
        <div className="flex py-10 flex-wrap">
          {affiliates &&
            affiliates.map((affiliate, key) => (
              (affiliate.slug.current != 'advancing-justice-all-affiliates') ?
              (<div
                key={key}
                className="flex flex-col gap-2 items-center px-3 w-52 my-2 justify-start mx-auto"
              >
                <img
                  src={urlFor(affiliate.image.asset.url).width(300).url()}
                  alt=""
                  className="object-cover"
                />
                <p className="text-sm font-semibold text-center">
                  {affiliate.name}
                </p>
                <Link
                  to={`/about-us/${affiliate.slug.current}`}
                  className="text-xs underline uppercase"
                >
                  Learn More
                </Link>
              </div>) : null
            ))}
        </div>
      </div>
    </div>
  );
}
